<template>
    <div class="xl:container m-10 mx-auto px-1">


        <h1 class="text-3xl ">Прикрепите необходимые документы</h1>


        <select v-model="selected" >
            <option value="" selected disabled>Выберите организацию</option>
            <option :value="contract.id" v-for="contract in contracts" :key="contract.id">{{ contract.uf_name }}
            </option>
        </select>


        <div class="flex sm:flex-col xl:flex-row items-end my-5 ">
            <div class="sm:w-full xl:w-1/2 xl:mr-1">
                <h3 class="text-xl mb-2">Cвидетельство о регистрации</h3>
                <vue-dropzone ref="registration"
                              class="dark:bg-gray-800 rounded bg-gray-300 dark:hover:border-gray-800 dark:border-gray-700 sm:mb-10 xl:mb-0"
                              id="dropzone1" :options="dropzoneOptions"></vue-dropzone>
            </div>
            <div class="sm:w-full xl:w-1/2 xl:ml-1">
                <h3 class="text-xl mb-2">Документы подтверждающие полномочия представителя на заключение договора </h3>
                <vue-dropzone ref="credentials"
                              class="dark:bg-gray-800 rounded bg-gray-300 dark:hover:border-gray-800 dark:border-gray-700"
                              id="dropzone2"
                              :options="dropzoneOptions"></vue-dropzone>
            </div>
        </div>

        <button class="px-6 py-3  rounded" :class="{'bg-gray-300':loading,'bg-green-400':!loading}" :disabled="loading"
                @click="submit">{{ message }}
        </button>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from "axios";
import {mapGetters} from "vuex";

export default {
    meta: {
        title: 'Прикрепить документы'
    },
    name: "AttachDocs",
    components: {
        vueDropzone: vue2Dropzone
    },
    data: () => ({
        selected: '',
        loading: false,
        dropzoneOptions: {
            url: 'https://declarant.by/api/user/attach-documents.php',
            autoProcessQueue: false,
            thumbnailWidth: 150,
            maxFilesize: 50000000,
            maxFiles: 1,
            uploadMultiple: true,
            addRemoveLinks: true,
            dictDefaultMessage: 'Перетащите файлы для загрузки',
            dictMaxFilesExceeded: 'Разрешен только один файл',
            dictRemoveFile: 'Удалить'
        },
    }),
    created() {
        this.$store.dispatch('admin/getContractsAll')
    },
    computed: {
        message() {
            if (!this.loading) {
                return 'Отправить'
            }
            return 'Идет загрузка...'
        },
        ...mapGetters({
            contracts: 'admin/contracts'
        })

    },
    methods: {
        clear() {
            this.$refs.registration.removeAllFiles()
            this.$refs.credentials.removeAllFiles()

        },
        setOK() {
            let progress = document.getElementsByClassName('dz-progress')
            for (let item of progress) {
                item.style.opacity = 0
            }
            let success = document.getElementsByClassName('dz-success-mark')
            for (let item of success) {
                item.style.opacity = 1
            }
        },
        setFail() {
            let progress = document.getElementsByClassName('dz-progress')
            for (let item of progress) {
                item.style.opacity = 0
            }
            let error = document.getElementsByClassName('dz-error-mark')
            for (let item of error) {
                item.style.opacity = 0
            }
        },
        submit() {
            let registration = this.$refs.registration.getQueuedFiles()
            let credentials = this.$refs.credentials.getQueuedFiles()

            if (credentials[0] && registration[0]) {
                this.loading = true

                let data = new FormData()
                data.append('registration', registration[0])
                data.append('credentials', credentials[0])
                data.append('id', this.selected)


                axios.post('https://declarant.by/rest/user/contract/attach', data)
                    .then(res => {
                        this.loading = false
                        this.setOK()
                        this.$notify({
                            title: 'Успешно',
                            text: res.data.data,
                            type: 'success'
                        })
                        this.clear()
                    }).catch(err => {
                    this.setFail()
                    this.loading = false
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                    this.clear()
                })
            } else {
                this.$notify({
                    title: 'Внимание',
                    text: 'Необходимо прикрепить оба файла',
                    type: 'warn'
                })
            }


        },

    }
}
</script>

<style scoped>
select{
    @apply mt-5 sm:w-full  text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base  dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    rounded bg-gray-200  focus:border-gray-500 dark:bg-gray-800
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white;
}
.dark .vue-dropzone:hover {
    background-color: rgb(38, 38, 38)
}
* >>> .dz-progress {
    display: none !important;
    opacity: 0 !important;
}
</style>
